<template>
  <div>
    <row-select v-for="row in content.body" :key="row.id" :row="row"/>
  </div>
</template>

<script>
  import store from '@/store'

  export default {
    name: 'Page',
    computed: {
      slug () {
        return this.$route.params.slug
      },
      page () {
        return store.state.pages[this.slug]
      },
      content () {
        return this.page?.data
      }
    },
    methods: {
      seo () {
        store.commit('SEO', this.content)
      }
    },
    watch: {
      slug (newVal) {
        if (newVal) {
          this.seo()
        }
      }
    },
    mounted () {
      this.seo()
    },
    beforeRouteEnter (to, from, next) {
      const { slug } = to.params
      store.dispatch('getPage', slug).then(next)
    },
    beforeRouteUpdate (to, from, next) {
      const { slug } = to.params
      store.dispatch('getPage', slug).then(next)
    }
  }
</script>
